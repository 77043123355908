<template>
  <div id="customer-list-list-view" class="customer-list-container">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Customers'">
      <template v-slot:right-end>
        <div class="vx-row">
          <!--          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat" icon-pack="feather" icon="icon-x" size="small" @click="clearSelectedAlpha()"></vs-button>-->
          <!--          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26" v-on:change="pageChanged"></vs-pagination-modified>-->
          <div class="p-3"></div>
          <vs-button
            v-if="activeUserInfo.has_customers_read === 1 || activeUserInfo.has_customers === 1 || activeUserInfo.internal_role === 0"
            @click="openAddCustomerPage" type="filled" icon-pack="feather"
            icon="icon-plus">{{ $t('AddCustomer') }}
          </vs-button>
        </div>
      </template>
    </breadcrumb-base>


    <!--    &lt;!&ndash;    Empty List State &ndash;&gt;-->
    <!--    <transition name="fade">-->
    <!--      <div class="h-screen flex w-full bg-img" v-if="customers.length === 0 && !errorFetching">-->
    <!--        <div-->
    <!--          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">-->
    <!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
    <!--               class="mx-auto mb-4 max-w-full">-->
    <!--          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </transition>-->

    <!--    Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div>
        <div class="vx-row w-full">

          <div class="vx-col mt-3">
            <label style="font-size: 12px; font-weight: bold">{{ $t('Status') }}
              <span
                @click="selectedStatus = null;onFilterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-4 w-full pb-3"
              v-model="selectedStatus"
              @input="onFilterChanged"
              :placeholder="$t('Status')"
            >
              <vs-select-item :key="index" :value="f.value" :text="f.name" v-for="(f,index) in status"/>
            </vs-select>
          </div>

          <div class="vx-col mt-3">
            <label style="font-size: 12px; font-weight: bold">{{ $t('Flag') }}
              <span
                @click="selectedFlag = null;onFilterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-4 w-full pb-3"
              v-model="selectedFlag"
              @input="onFilterChanged"
              :placeholder="$t('Flag')"
            >
              <vs-select-item :key="index" :value="f.id" :text="f.name" v-for="(f,index) in flags"/>
            </vs-select>
          </div>
          <div class="vx-col mt-3"
               v-if="showMangerDropDown">

            <label style="font-size: 12px; font-weight: bold">{{ $t('Manager') }}
              <span
                @click="selectedManager = null;onFilterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-2 w-full pb-3"
              v-model="selectedManager"
              @input="onFilterChanged(-1)"
              :placeholder="$t('Manager')"
            >
              <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
              <vs-select-item :key="index" :value="customer" :text="customer.user_name"
                              v-for="(customer,index) in usersRaw.manager_accounts"/>
            </vs-select>
          </div>

          <div class="vx-col mt-3"
               v-if="showRefererDropDown">

            <label style="font-size: 12px; font-weight: bold">{{ $t('Staff') }}
              <span
                @click="selectedReferer = null;onFilterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="pr-2 w-full pb-3"
              v-model="selectedReferer"
              @input="onFilterChanged"
              :placeholder="$t('Staff')"
            >
              <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
              <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                              v-for="(customer,index) in staffAccounts"/>
            </vs-select>
          </div>

          <div class="flex m-auto">
            <div class="">
              <vs-button @click="clearAllFilters">{{ $t('ClearAll') }}</vs-button>
            </div>
          </div>

          <vs-spacer></vs-spacer>
        </div>

        <div class="flex flex-row space-x-2">

          <div
            class="con-input-search vs-table--search">
            <!-- @input="searchDebounce"-->
            <vs-input
              class="input-search"
              :disabled="isSearching"
              v-model="searchText"
              type="text"
              icon-no-border icon="search"/>
          </div>

          <vs-button @click="searchCustomers()">{{ $t('Search') }}</vs-button>

          <div class="flex flex-row items-center space-x-2" >
            <vs-button @click="viewAllCustomers()">{{ viewAll ? $t('ShowLess') : $t('ViewAll') }}</vs-button>
            <template v-if="customers.length > 0">
              <vs-button @click="onOpenDownloadEmailModal(false)">{{ $t('DownloadCSV') }}</vs-button>
              <vs-button @click="onOpenDownloadEmailModal(true)">{{ $t('DownloadExcel') }}</vs-button>
            </template>
          </div>

        </div>
        <div class="hidden md:block">
          <vs-table-modified v-show="!errorFetching" ref="table"
                             :max-items="itemsPerPage"
                             :data="customers">

            <!--        v-on:selected="openCustomerDetailPage"-->
            <template slot="header">

            </template>

            <template slot="thead">
              <vs-th>
                <p style="display: inline-flex;" @click="updateSort('by_refer')">
                  <span style="line-height: 24px;">{{ $t('Reference') }}</span>
                  <span class="material-icons" v-if="sortKey === 'by_refer' && sortOrder === 0">
                arrow_drop_down
                </span>
                  <span class="material-icons" v-if="sortKey === 'by_refer' && sortOrder == 1">
                arrow_drop_up
                </span>
                </p>
              </vs-th>
              <vs-th>
                <p style="display: inline-flex;" @click="updateSort('by_customer_id')">
                  <span style="line-height: 24px;">{{ $t('ID') }}</span>
                  <span class="material-icons" v-if="sortKey === 'by_customer_id' && sortOrder == 0">
                arrow_drop_down
                </span>
                  <span class="material-icons" v-if="sortKey === 'by_customer_id' && sortOrder == 1">
                arrow_drop_up
                </span>
                </p>
              </vs-th>
              <vs-th>
                <p style="display: inline-flex;" @click="updateSort('by_name')">
                  <span style="line-height: 24px;">{{ $t('Name') }}</span>
                  <span class="material-icons" v-if="sortKey === 'by_name' && sortOrder == 0">
                arrow_drop_down
                </span>
                  <span class="material-icons" v-if="sortKey === 'by_name' && sortOrder == 1">
                arrow_drop_up
                </span>
                </p>
              </vs-th>
              <vs-th>
                <div class="vs-col">
                  <p class="">{{ $t('City') }}</p>
                  <p class="">{{ $t('PostalCode') }}</p>
                </div>
              </vs-th>
              <vs-th>{{ $t('Mobile') }}</vs-th>
              <vs-th v-if="activeUserInfo.account_role == 0 || !isNotMobilePins">{{ $t('Username') }}</vs-th>
              <vs-th v-if="activeUserInfo.account_role == 1 && isNotMobilePins">{{ $t('ActivatedOn') }}</vs-th>
              <vs-th>
                <p style="display: inline-flex;" @click="updateSort('by_credit')">
                  <span style="line-height: 24px;">{{ $t('Credit') }}</span>
                  <span class="material-icons" v-if="sortKey === 'by_credit' && sortOrder == 0">
                arrow_drop_down
                </span>
                  <span class="material-icons" v-if="sortKey === 'by_credit' && sortOrder == 1">
                arrow_drop_up
                </span>
                </p>
              </vs-th>
              <vs-th>
                <p style="display: inline-flex;" @click="updateSort('by_balance')">
                  <span style="line-height: 24px;">{{ $t('Balance') }}</span>
                  <span class="material-icons" v-if="sortKey === 'by_balance' && sortOrder == 0">
                arrow_drop_down
                </span>
                  <span class="material-icons" v-if="sortKey === 'by_balance' && sortOrder == 1">
                arrow_drop_up
                </span>
                </p>
              </vs-th>
              <vs-th>
                <p style="display: inline-flex;" @click="updateSort('by_overdue_day')">
                  <span style="line-height: 24px;">{{ $t('OverdueDays') }}</span>
                  <span class="material-icons" v-if="sortKey === 'by_overdue_day' && sortOrder === 0">
                arrow_drop_down
                </span>
                  <span class="material-icons" v-if="sortKey === 'by_overdue_day' && sortOrder == 1">
                arrow_drop_up
                </span>
                </p>
              </vs-th>
              <vs-th>
                <p style="display: inline-flex;" @click="updateSort('by_last_purchased_at')">
                  <span style="line-height: 24px;">{{ $t('LastPurchase') }}</span>
                  <span class="material-icons" v-if="sortKey === 'by_last_purchased_at' && sortOrder == 0">
                arrow_drop_down
                </span>
                  <span class="material-icons" v-if="sortKey === 'by_last_purchased_at' && sortOrder == 1">
                arrow_drop_up
                </span>
                </p>
              </vs-th>
              <vs-th>
                <p style="display: inline-flex;" @click="updateSort('by_yesterday_sale')">
                  <span style="line-height: 24px;">{{ $t('YesterdaySales') }}</span>
                  <span class="material-icons" v-if="sortKey === 'by_yesterday_sale' && sortOrder === 0">
                arrow_drop_down
                </span>
                  <span class="material-icons" v-if="sortKey === 'by_yesterday_sale' && sortOrder == 1">
                arrow_drop_up
                </span>
                </p>
              </vs-th>
              <vs-th>{{ $t('LastCashPaid') }}</vs-th>
              <vs-th>{{ $t('CurrentMonthSales') }}</vs-th>
              <vs-th>{{ $t('Actions') }}</vs-th>
              <!--          <vs-th >{{ $t('LastPaidAmount') }}</vs-th>-->
            </template>

            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p @click="openCustomerDetailPage(tr)"
                     class="font-medium truncate px-4">{{ tr.refer }}</p>
                </vs-td>
                <vs-td>
                  <vx-tooltip>
                    <template slot="tip">
                      <p v-if="tr.remarks ">{{ $t('Remark') }}: <strong>{{ tr.remarks }}</strong>
                      </p>
                    </template>
                    <div class="vs-col">
                      <p class="font-medium truncate flex">
                        <feather-icon icon="CircleIcon"
                                      :svgClasses="['fill-current h-6 w-6', textColor(tr.flag)]"/>
                        <span v-if="tr.remarks" :id="tr.customer_id"
                              @dblclick="copyToClipboard(tr.customer_id)"
                              style="color: rgb(var(--vs-primary));font-weight: bold;">{{
                            tr.customer_id
                          }}</span>
                        <span v-else style="font-weight: bold;" :id="tr.customer_id"
                              @dblclick="copyToClipboard(tr.customer_id)">{{
                            tr.customer_id
                          }}</span>
                      </p>
                    </div>
                  </vx-tooltip>

                </vs-td>
                <vs-td>
                  <vx-tooltip>
                    <template slot="tip">
                      <p>{{ $t('Organisation') }}: <strong>{{ tr.organisation_name }}</strong></p>
                      <!--                  <p>{{ $t('LastActive') }}: <strong>{{ tr.activated_on | dateFormat }}</strong></p>-->
                    </template>
                    <div @click="openCustomerDetailPage(tr)" class="flex flex-col">
                      <p class="font-medium truncate"
                         style="max-width: 150px;white-space: normal !important;padding: 0 8px;">
                        {{ tr.name }}
                      </p>
                      <p class="subtitle" style="font-size: 12px;">
                        {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                      </p>
                    </div>
                  </vx-tooltip>
                </vs-td>
                <vs-td>
                  <div @click="openCustomerDetailPage(tr)" class="vs-col">
                    <p class="font-medium truncate">{{ tr.city }}</p>
                    <p class="font-medium truncate">{{ tr.postal_code }}</p>
                  </div>
                </vs-td>
                <vs-td>
                  <div @click="openCustomerDetailPage(tr)" class="vs-col">
                    <p class="font-medium truncate">{{ tr.mobile }}</p>
                  </div>
                </vs-td>
                <vs-td v-if="activeUserInfo.account_role == 1 && isNotMobilePins">
                  <vx-tooltip>
                    <template slot="tip">
                      <div @click="openCustomerDetailPage(tr)" class="flex">
                        <p v-if="tr.is_active" style="color: rgb(var(--vs-success));">
                          <strong>{{ tr.username }}</strong>
                        </p>
                        <p v-else style="color: rgb(var(--vs-danger));">
                          <strong>{{ tr.username }}</strong>
                        </p>
                      </div>
                    </template>
                    <p>
                      <strong>{{ tr.activated_on | dateFormat }}</strong>
                    </p>
                  </vx-tooltip>
                </vs-td>
                <vs-td v-if="activeUserInfo.account_role == 0 || !isNotMobilePins">
                  <div @click="openCustomerDetailPage(tr)" class="flex">
                    <p v-if="tr.is_active" style="color: rgb(var(--vs-success));">
                      <strong>{{ tr.username }}</strong>
                    </p>
                    <p v-else style="color: rgb(var(--vs-danger));">
                      <strong>{{ tr.username }}</strong>
                    </p>
                  </div>
                </vs-td>
                <vs-td>
                  <div @click="openAddCreditPage(tr.customer_id)" class="vs-row vx-col">
                    <vx-tooltip>
                      <template slot="tip">
                        <p>{{ $t('CreditLimit') }}: <strong>{{ tr.credit_limit | germanNumberFormat }}</strong></p>
                        <p>{{ $t('BalanceTrigger') }}: <strong>{{ tr.balance_trigger | germanNumberFormat }}</strong></p>
                        <p>{{ $t('BalanceTopUp') }}: <strong>{{ tr.balance_top_up | germanNumberFormat }}</strong></p>
                      </template>
                      <div class="flex flex-row items-center space-x-1">
                        <div class="w-3 h-3 bg-success rounded-full" v-if="tr.has_auto_credit == 1">
                        </div>
                        <p class="font-medium truncate " :class="[tr.auto_credit == 1 ? 'text-success' : 'text-red-500']">
                          {{ tr.credit | germanNumberFormat }}
                          <span @click=""> <vs-icon
                            style="padding: 2px; border-radius: 6px;" bg="primary" color="white"
                            icon-pack="feather" icon="icon-plus" @click="openAddCreditPage(tr.customer_id)"></vs-icon>
                      </span>
                        </p>
                      </div>
                    </vx-tooltip>

                  </div>

                </vs-td>
                <vs-td>
                  <p @click="openCustomerDetailPage(tr)" class="font-medium truncate">{{
                      tr.balance | germanNumberFormat
                    }}</p>
                </vs-td>
                <vs-td>
                  <p @click="openCustomerDetailPage(tr)" class="font-medium truncate"
                     @mouseenter="showProjectsDialog(tr)"
                  >{{ tr.over_due_days }}</p>

                </vs-td>
                <vs-td>
                  <p @click="openCustomerDetailPage(tr)" class="font-medium"
                     style="max-width: 100px; word-break: normal !important;">
                    {{ tr.last_purchase | dateFormat }}</p>
                </vs-td>
                <vs-td>
                  <p @click="openCustomerDetailPage(tr)" class="font-medium">{{
                      tr.yesterday_sales | germanNumberFormat
                    }}</p>
                </vs-td>
                <vs-td>

                  <vx-tooltip>
                    <template slot="tip">
                      <p><strong>{{ tr.last_cash_paid_at | dateFormat }}</strong></p>
                    </template>
                    <p @click="openCustomerDetailPage(tr)" class="font-medium truncate">
                      {{ tr.last_cash_paid | germanNumberFormat }}</p>
                  </vx-tooltip>
                </vs-td>
                <vs-td>
                  <p class="font-medium truncate">
                    {{ tr.current_month_sales | germanNumberFormat }}
                  </p>
                </vs-td>
                <!--            <vs-td>-->
                <!--              <p class="font-medium truncate">{{ tr.last_paid_amount | germanNumberFormat }}</p>-->
                <!--            </vs-td>-->
                <vs-td>
                  <div class="flex flex-row">
<!--                    <vs-button @click="showRemarkModal(tr)" type="filled">-->
<!--                      {{ $t('UpdateRemark') }}-->
<!--                    </vs-button>-->
                    <vs-button color="success" radius type="border"
                               @click="showRemarkModal(tr)"
                               icon="comment"></vs-button>
                  </div>
                </vs-td>

              </vs-tr>
              <vs-tr class="bg-primary" style="font-size: 1rem;" v-if="viewAll">
                <vs-th></vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
                <vs-th>
                  <p class="truncate text-white py-2" style="font-weight: bold;"> {{ customersRaw.customers_total_credit | germanNumberFormat }} </p>
                </vs-th>
                <vs-th>
                  <p class="truncate text-white py-2" style="font-weight: bold;"> {{ customersRaw.customers_total_balance | germanNumberFormat }} </p>
                </vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
        <div class="table-mobile-customers md:hidden my-4">
          <!--            <div class="input">-->
          <!--              <input-->
          <!--                class="w-full px-4 py-2 rounded-lg border-2 border-primary"-->
          <!--                v-model="searchWalletText"-->
          <!--                placeholder="Search">-->
          <!--            </div>-->
          <div class="" v-if="customers.length > 0">

            <table v-for="(tr, index) in customers">

              <tbody>
              <tr>
                <th>{{ $t('Reference') }}</th>
                <th>
                  <p @click="openCustomerDetailPage(tr)"
                     class="font-medium truncate px-4">{{ tr.refer }}</p>
                </th>
              </tr>
              <tr>
                <th>{{ $t('ID') }}</th>
                <th>
                  <div class="vs-col">
                    <p class="font-medium truncate flex">
                      <feather-icon icon="CircleIcon" :svgClasses="['fill-current h-6 w-6', textColor(tr.flag)]"/>
                      <span v-if="tr.remarks" :id="tr.customer_id" @dblclick="copyToClipboard(tr.customer_id)"
                            style="color: rgb(var(--vs-primary));font-weight: bold;">{{
                          tr.customer_id
                        }}</span>
                      <span v-else style="font-weight: bold;" :id="tr.customer_id"
                            @dblclick="copyToClipboard(tr.customer_id)">{{ tr.customer_id }}</span>
                    </p>
                  </div>
                </th>
              </tr>
              <tr>
                <th>{{ $t('Name') }}</th>
                <th>
                  <vx-tooltip>
                    <template slot="tip">
                      <p>{{ $t('Organisation') }}: <strong>{{ tr.organisation_name }}</strong></p>
                      <!--                  <p>{{ $t('LastActive') }}: <strong>{{ tr.activated_on | dateFormat }}</strong></p>-->
                    </template>
                    <div @click="openCustomerDetailPage(tr)" class="flex flex-col">
                      <p class="font-medium truncate"
                         style="max-width: 150px;white-space: normal !important;padding: 0 8px;">
                        {{ tr.name }}
                      </p>
                      <p class="subtitle" style="font-size: 12px; color: black">
                        {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                      </p>
                    </div>
                  </vx-tooltip>
                </th>
              </tr>
              <tr>
                <th>{{ $t('City') }} / {{ $t('PostalCode') }}</th>
                <th>
                  <div @click="openCustomerDetailPage(tr)" class="vs-col">
                    <p class="font-medium truncate">{{ tr.city }} / {{ tr.postal_code }}</p>
                  </div>
                </th>
              </tr>
              <tr>
                <th>{{ $t('Mobile') }}</th>
                <th>
                  <div @click="openCustomerDetailPage(tr)" class="vs-col">
                    <p class="font-medium truncate">{{ tr.mobile }}</p>
                  </div>
                </th>
              </tr>
              <tr>
                <th>{{ $t('Username') }}</th>
                <th>
                  <div @click="openCustomerDetailPage(tr)" class="flex">
                    <p v-if="tr.is_active" style="color: rgb(var(--vs-success));">
                      <strong>{{ tr.username }}</strong>
                    </p>
                    <p v-else style="color: rgb(var(--vs-danger));">
                      <strong>{{ tr.username }}</strong>
                    </p>
                  </div>
                </th>
              </tr>
              <tr>
                <th>{{ $t('Credit') }}</th>
                <th>
                  <div @click="openAddCreditPage(tr.customer_id)" class="vs-row vx-col">
                    <p class="font-medium truncate " :class="[tr.auto_credit == 1 ? 'text-success' : 'text-red-500']">
                      {{ tr.credit | germanNumberFormat }} <span @click=""> <vs-icon
                      style="padding: 2px; border-radius: 6px;" bg="primary" color="white"
                      icon-pack="feather" icon="icon-plus" @click="openAddCreditPage(tr.customer_id)"></vs-icon></span>
                    </p>
                  </div>
                </th>
              </tr>
              <tr>
                <th>{{ $t('Balance') }}</th>
                <th>
                  <p @click="openCustomerDetailPage(tr)" class="font-medium truncate">{{
                      tr.balance | germanNumberFormat
                    }}</p>
                </th>
              </tr>
              <tr>
                <th>{{ $t('OverdueDays') }}</th>
                <th>
                  <p @click="showProjectsDialog(tr)" class="font-medium truncate"
                  >{{ tr.over_due_days }}</p>
                </th>
              </tr>
              <tr>
                <th>{{ $t('LastPurchase') }}</th>
                <th>
                  <p @click="openCustomerDetailPage(tr)" class="font-medium"
                     style="max-width: 100px; word-break: normal !important;">
                    {{ tr.last_purchase | dateFormat }}</p>
                </th>
              </tr>
              <tr>
                <th>{{ $t('YesterdaySales') }}</th>
                <th>
                  <p @click="openCustomerDetailPage(tr)" class="font-medium">{{
                      tr.yesterday_sales | germanNumberFormat
                    }}</p>
                </th>
              </tr>
              <tr>
                <th>{{ $t('LastCashPaid') }}</th>
                <th>
                  <vx-tooltip>
                    <template slot="tip">
                      <p><strong>{{ tr.last_cash_paid_at | dateFormat }}</strong></p>
                    </template>
                    <p @click="openCustomerDetailPage(tr)" class="font-medium truncate">
                      {{ tr.last_cash_paid | germanNumberFormat }}</p>
                  </vx-tooltip>
                </th>
              </tr>
              <tr>
                <th>{{ $t('CurrentMonthSales') }}</th>
                <th>
                  <p @click="openCustomerDetailPage(tr)" class="font-medium truncate">
                    {{ tr.current_month_sales | germanNumberFormat }}</p>
                </th>
              </tr>
              </tbody>
            </table>
            <div class="flex justify-center">
              <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
              </vs-new-pagination>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <vx-modal v-if="showProjectionsModal" @close="showProjectionsModal = false" component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <customer-projects-component
            @close="closeProjectsDialog"
            :projections="selectedProjections"/>
        </div>
      </template>
    </vx-modal>


    <vx-modal v-if="showUpdateRemark" @close="hideRemarkModal"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <vx-col>
            <h3>Update Remark</h3>
            <div class="p-2"></div>
            <p v-if="customer.remarks">{{ customer.remarks }}</p>
            <div class="p-2"></div>
            <vs-textarea :label="$t('Remarks')"
                         v-model="remarks"/>

            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="updateRemark()">{{
                  $t('Update')
                }}
              </vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showUpdateRemark = false">
                {{ $t('Close') }}
              </vs-button>
            </div>
          </vx-col>
        </div>
      </template>
    </vx-modal>


    <vx-modal v-if="showDownloadEmailModal" component-class="modal-container-fit">
      <template slot="body">
        <div style="min-width: 300px; overflow-y: auto;">
          <div class="text-center status-card" >
            <div class="flex flex-col py-4 space-y-4">
              <h1>
                {{ $t('GetCustomersToEmail') }}
              </h1>
              <div class="">

                <vs-input class="p-3 w-full"
                          :placeholder="$t('EmailId')" v-model="downloadEmail"/>
              </div>
              <div class="flex flex-row">
                <vs-button
                  class="ml-2"
                  @click="onCloseDownloadEmailModal" color="danger">
                  {{ $t('Cancel') }}
                </vs-button>
                <vs-button
                  class="ml-2"
                  @click="onCloseDownloadEmailModal(downloadEmail)" >{{ $t('Download') }}</vs-button>
              </div>
            </div>
          </div>

        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>

import VxModal from "@/layouts/components/custom/VxModal";
import CustomerProjectsComponent from "@/views/pages/superadmin/customer/components/CustomerProjectsComponent";

export default {
  name: 'ViewCustomer',
  components: {CustomerProjectsComponent, VxModal},
  data() {
    return {
      isExcel: false,
      downloadEmail: "",
      showDownloadEmailModal: false,
      searchText: '',
      itemsPerPage: 20,
      isSearching: false,
      isMounted: false,
      errorFetching: false,
      selectedManager: null,
      selectedReferer: null,
      selectedFlag: null,
      selectedStatus: true,
      selectedCreditSort: null,
      selectedBalanceSort: null,
      remarks: '',
      showUpdateRemark: false,
      customer: null,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Customers', i18n: 'Customers', active: true},
      ],
      flags: [
        {
          id: 0,
          name: 'Red',
        },
        {
          id: 1,
          name: 'Green',
        },
        {
          id: 2,
          name: 'Yellow',
        },
      ],
      currentPage: 1,
      viewAll: false,
      showProjectionsModal: false,
      selectedProjections: [],
      sortKey: '',
      sortOrder: -1,
      status: [
        {
          name: 'All',
          value: null,
        },
        {
          name: 'Active',
          value: true,
        },
        {
          name: 'Deactivate',
          value: false,
        },
      ]
    }
  },
  computed: {
    isNotMobilePins() {
      return window.location.host.indexOf("mobilepin.de") === -1;
    },
    vPage() {
      return this.$store.state.customer.vPage
    },
    vTotalCount() {
      return this.$store.state.customer.vTotalCount
    },
    vTotalPages() {
      return this.$store.state.customer.vTotalPages
    },
    usersRaw() {
      return this.$store.state.user.usersRaw || {}
    },
    customers() {
      return this.$store.state.customer.customers
    },
    customersRaw() {
      return this.$store.state.customer.customersRaw
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.customers.length
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    users() {
      return this.$store.state.user.users
    },
    staffAccounts() {
      if (this.selectedManager != null) {
        return this.selectedManager.staff_accounts
      } else {
        return this.usersRaw.staff_accounts;
      }
    },
    showMangerDropDown() {
      return this.activeUserInfo.internal_role === 0;
    },
    showRefererDropDown() {
      return this.activeUserInfo.internal_role === 0 || this.activeUserInfo.staff_role === 1;
    }
  },
  methods: {
    onOpenDownloadEmailModal(isExcel = false) {

        this.showDownloadEmailModal = true;
        this.isExcel = isExcel
    },
    onCloseDownloadEmailModal(email) {
      if(email) {
        this.performDownloadCustomers(!this.isExcel);
      }
      this.showDownloadEmailModal = false;
    },
    showRemarkModal(tr) {
      this.customer = tr;
      this.remarks = '';
      this.showUpdateRemark = true;
    },
    hideRemarkModal() {
      this.customer = null;
      this.remarks = '';
      this.showUpdateRemark = false;
    },
    async updateRemark() {
      const payload = {
        remarks: this.remarks,
        customer_id: this.customer.customer_id,
      }
      this.$vs.loading()
      const data = await this.$store.dispatch('customer/updateCustomerRemark', payload).catch((error) => {
        this.$vs.loading.close()
        this.hideRemarkModal();
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.response.statusText,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
      if (data) {
        this.hideRemarkModal()
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.loadCustomer()
        return true
      }
      this.hideRemarkModal()
      this.$vs.loading.close()
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: 'Unknown error',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
    copyToClipboard(id) {
      var r = document.createRange();
      r.selectNode(document.getElementById(id));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(r);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    },
    updateSort(key) {
      if (key === this.sortKey) {
        if (this.sortOrder == -1) {
          this.sortOrder = 0;
        } else if (this.sortOrder == 0) {
          this.sortOrder = 1;
        } else if (this.sortOrder == 1) {
          this.sortOrder = -1;
        }
      } else {
        this.sortOrder = 0
      }
      this.sortKey = key;
      this.loadCustomer('0', this.searchText)
    },
    showProjectsDialog(tr) {
      if (tr.over_due_amount > 0) {
        this.selectedProjections = tr.projection_records
        this.showProjectionsModal = true;
      }
    },
    closeProjectsDialog() {

      this.selectedProjections = []
      this.showProjectionsModal = false;
    },
    textColor(flag) {
      if (flag == 0) {
        return 'text-danger'
      }
      if (flag == 1) {
        return 'text-success'
      }
      if (flag == 2) {
        return 'text-warning'
      }
      return flag ? 'text-primary' : 'text-grey'
    },
    openCustomerPAD(item) {
      if (this.activeUserInfo.has_discount_slab === 1) {
        this.$router.push({name: 'customer-pad', params: {customerId: item}})
      }
    },
    openAddCreditPage(item) {
      if (this.activeUserInfo.has_credit_and_balance === 1) {
        this.$router.push({name: 'customer-credit-add', params: {customerId: item}})
      }
    },
    openCustomerEditPage(item) {
      if (this.activeUserInfo.has_customers_read === 1 || this.activeUserInfo.has_customers === 1 || this.activeUserInfo.internal_role === 0) {
        this.$router.push({name: 'customer-edit', params: {customerId: item}})
      }
    },
    openCustomerDetailPage(item) {
      if (this.activeUserInfo.has_customers === 1 || this.activeUserInfo.internal_role === 0) {
        this.$router.push({name: 'customer-details', params: {customerId: item.customer_id}})
      }
    },
    openAddCustomerPage() {
      if (this.activeUserInfo.has_customers_read === 1 || this.activeUserInfo.has_customers === 1 || this.activeUserInfo.internal_role === 0) {
        this.$router.push({name: 'customer-add'})
      }
    },
    getStatusColor(status) {
      if (status === 1) return 'success'
      return 'warning'
    },
    getStatusText(status) {
      if (status === 1) return 'Active'
      return 'In Active'
    },
    onFilterChanged(resetRefer) {
      if (resetRefer === -1) {
        this.selectedReferer = null
      }
      this.loadCustomer()
    },
    clearAllFilters() {
      this.errorFetching = false
      this.selectedManager = null
      this.selectedReferer = null
      this.selectedFlag = null
      this.selectedStatus = null
      this.selectedCreditSort = null
      this.selectedBalanceSort = null
      this.loadCustomer()
    },
    searchDebounce() {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.isSearching = true
        this.loadCustomer('0', this.searchText)
      }, 500)
    },
    searchCustomers() {
      this.loadCustomer('0', this.searchText)
    },
    listPageChanged(item) {
      this.loadCustomer(this.currentPage - 1)
    },
    loadReferer() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', {})
        .then((data) => {
          this.errorFetching = false
        })
        .catch((err) => {
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    viewAllCustomers() {
      this.viewAll = !this.viewAll;
      this.loadCustomer('0');
    },
    getFilters() {
      const p = {}
      if (this.selectedFlag !== null && this.selectedFlag !== undefined) {
        p.flag = this.selectedFlag
      }
      if (this.selectedStatus !== null && this.selectedStatus !== undefined) {
        p.is_active = this.selectedStatus
      }
      if (this.selectedManager) {
        p.refer_id = this.selectedManager.user_id
      }
      if (this.selectedReferer) {
        p.refer_id = this.selectedReferer
      }
      if (this.selectedCreditSort) {
        p.sort_by_credit = this.selectedCreditSort
      }
      if (this.selectedBalanceSort) {
        p.sort_by_balance = this.selectedBalanceSort
      }
      if (this.sortKey !== '' && this.sortOrder !== -1) {
        p[this.sortKey] = this.sortOrder;
      }
      if(this.viewAll) {
        p['view_all'] = this.viewAll ? 1 : 0;
      }
      return p
    },
    performDownloadCustomers(isCSV = false) {
      this.downloadCustomers(0, this.searchText, isCSV)
    },
    downloadCustomers(page = '0', search = '', isCSV = false) {
      if (page == 0) this.currentPage = 1
      const filters = this.getFilters()
      filters.download = 2
      if (!isCSV) {
        filters.download_type = 'excel'
      }
      if(this.downloadEmail) {
        filters.download_email = this.downloadEmail;
        this.downloadEmail = "";
      }
      this.$vs.loading()
      this.$store.dispatch('customer/fetchDownloadCustomers', {filters, page, search})
        .then((data) => {

          this.$vs.loading.close()
          this.errorFetching = false
          this.downloadEmail = "";
          try {
            if(data.download_id) {
              this.$vs.notify({
                position: 'top-center',
                title: 'Success',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
              })
            } else {
              if(!isCSV) {
                const hiddenElement = document.createElement('a')
                hiddenElement.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
                hiddenElement.target = '_blank'
                hiddenElement.download = 'customers.xlsx';
                hiddenElement.click()
              } else {
                const hiddenElement = document.createElement('a')
                hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
                hiddenElement.target = '_blank'
                hiddenElement.download = 'customers.txt';
                hiddenElement.click()
              }
            }
          } catch (e) {
            if(!isCSV) {
              const hiddenElement = document.createElement('a')
              hiddenElement.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
              hiddenElement.target = '_blank'
              hiddenElement.download = 'customers.xlsx';
              hiddenElement.click()
            } else {
              const hiddenElement = document.createElement('a')
              hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
              hiddenElement.target = '_blank'
              hiddenElement.download = 'customers.txt';
              hiddenElement.click()
            }
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadCustomer(page = '0') {
      if (page == 0) this.currentPage = 1
      const filters = this.getFilters()
      this.$vs.loading()
      this.$store.dispatch('customer/fetchCustomers', {filters, page, search: this.searchText})
        .then((data) => {
          this.$vs.loading.close()
          this.isSearching = false
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.isSearching = false
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    // this.loadCustomer()
    this.loadReferer()
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
.small-c-button {
  width: 20px !important;
  height: 20px !important;
}

#customer-list-list-view {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .supplier-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .customer-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .supplier-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 .2rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 8px 2px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }

  .subtitle {
    font-size: 10px;
    color: #9c9c9c;
  }
}

.material-icons {
  display: inline-flex;
  vertical-align: top;
}

.table-mobile-customers {
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
  }

  /* Zebra striping */
  tr:nth-of-type(odd) {
    background: #eee;
  }

  tr {
    margin: 10px;

    th:first-child {
      width: 140px;
    }
  }

  //th {
  //  background: #333;
  //  color: white;
  //  font-weight: bold;
  //}
  td, th {
    padding: 6px;
    border: 1px solid #ccc;
    text-align: left;
  }
}
</style>
